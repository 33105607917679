import { Box } from "@gocardless/flux-react";

import { TwoPanelLeftPrimaryLayout } from "src/components/layout/core/TwoPanelLeftPrimaryLayout";
import { FlowHeader } from "src/components/layout/core/Headers/FlowHeader/FlowHeader";
import ThreatMetrixProfiler from "src/technical-integrations/threat-metrix";
import { TwoPanelLayout } from "src/components/layout/core/TwoPanelLayout";

export interface TwoPanelLayoutProps {
  leftPanel: React.ReactNode;
  rightPanel: React.ReactNode;
  totalSteps?: number;
  stepNumber?: number;
  primaryPanel?: "left" | "right";
}

export interface MobilePreviewProps {
  rightPanel: React.ReactNode;
  header?: string;
}

export const RequestPaymentTwoPanelLayout: React.FC<TwoPanelLayoutProps> = ({
  leftPanel,
  rightPanel,
  totalSteps,
  stepNumber,
  primaryPanel,
}) => {
  const Layout =
    primaryPanel === "left" ? TwoPanelLeftPrimaryLayout : TwoPanelLayout;

  return (
    <Box id="request-payment-two-panel">
      <ThreatMetrixProfiler />
      <Layout
        header={{
          content: <FlowHeader />,
          progressBar: {
            stepNumber,
            totalSteps,
          },
        }}
        leftPanel={<Box spaceBelow={6}>{leftPanel}</Box>}
        rightPanel={
          <Box height="100%" spaceBelow={6}>
            {rightPanel}
          </Box>
        }
      />
    </Box>
  );
};
